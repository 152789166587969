<template>
	<div class="w-full h-full">
		<div class="overflow-scroll h-full" ref="modalTop">
			<div v-if="!bannerImage" class="relative h-60 flex items-center justify-center" @mouseenter="toggleButtons" @mouseleave="toggleButtons">
				<img
					v-show="!bannerLoading"
					src="https://assets.stanwith.me/community/live/CommunityDefaultBanner/CommunityDefaultBanner.jpeg"
					class="w-full h-60 object-cover"
				/>
				<SolidDotsSpinner v-if="bannerLoading" color="var(--community-primary-color)" :dot-size="10" />
				<div class="flex flex-col md:flex-row w-full absolute bottom-0 justify-end">
					<AppButton
						v-if="canModifyChannels && showButtons"
						class="w-[50%] md:w-40 min-h-8 px-8 py-4 !absolute right-24 md:right-[5.5rem] bottom-4"
						size="xs"
						@click="openUnsplashImagePicker"
					>
						<AppIcon src="image" />
						Replace Image
					</AppButton>
					<AppButton
						v-if="canModifyChannels && showButtons"
						class="w-11 !h-[38px] min-h-8 px-8 py-2 !absolute right-4 md:right-8 bottom-4 md:bottom-4 !pl-[15px] !bg-white !border-0"
						size="xs"
						@click="removeBannerImage"
					>
						<AppIcon src="delete" class="color-primary mt-1.5" />
					</AppButton>
				</div>
			</div>
			<div v-else class="relative h-60 flex items-center justify-center" @mouseenter="toggleButtons" @mouseleave="toggleButtons">
				<img v-show="!bannerLoading" :src="bannerImage.url ?? bannerImage" class="w-full h-60 object-cover" />
				<SolidDotsSpinner v-if="bannerLoading" color="var(--community-primary-color)" :dot-size="10" />
				<div class="flex flex-col md:flex-row w-full absolute bottom-0 justify-end">
					<AppButton
						v-if="canModifyChannels && showButtons"
						class="w-[50%] md:w-40 min-h-8 px-8 py-4 !absolute right-24 md:right-[5.5rem] bottom-4"
						size="xs"
						@click="openUnsplashImagePicker"
					>
						<AppIcon src="image" />
						Replace Image
					</AppButton>
					<AppButton
						v-if="canModifyChannels && showButtons"
						class="w-11 !h-[38px] min-h-8 px-8 py-2 !absolute right-4 md:right-8 bottom-4 md:bottom-4 !pl-[15px] !bg-white !border-0"
						size="xs"
						@click="removeBannerImage"
					>
						<AppIcon src="delete" class="color-primary mt-1.5" />
					</AppButton>
				</div>
			</div>
			<div class="px-8">
				<AppInput
					v-if="canModifyChannels"
					v-model="title"
					placeholder="Give your resource a name"
					inputClass="!font-extrabold !pl-0 !text-3xl !bg-white"
					class="mt-4"
					:error="v$.title.$errors"
					@blur="v$.title.$reset()"
				/>
				<div v-else class="pl-2.5 font-extrabold text-3xl bg-white mx-12 mt-4">
					{{ title }}
				</div>
				<AppInput
					v-if="canModifyChannels"
					v-model="subtitle"
					inputClass="!bg-white !pl-0"
					placeholder="Add a brief description (Optional)..."
					:disabled="!canModifyChannels"
					@blur="v$.subtitle.$reset()"
					@input="v$.subtitle.$touch()"
				/>
				<div v-else class="bg-white pl-2.5 mx-12 mt-4 py-8">
					{{ subtitle }}
				</div>
				<div class="shadow-stan-box-shadow bg-stan-purple-light rounded-xl my-4 min-h-52" v-if="canModifyChannels">
					<TipTapEditor
						:should-show-link="false"
						v-model:value="content"
						placeholder="Write your content, format text, and attach media or files..."
						hasToolbar
						:shouldShowLink="true"
						:shouldShowImagePicker="true"
						@pick-file="uploadResourceFile"
						toolbar-class="!h-8 !mt-0 !bg-stan-purple-dark !rounded-md"
						class="grow bg-stan-gray-soft shadow-stan-box-shadow"
						editor-wrapper-class="px-4 py-4 rounded-b-xl bg-stan-purple-light h-fill"
					/>
				</div>
				<div v-else v-html="content" class="grow editor mx-12 my-4"></div>
			</div>

			<ResourceFiles
				:files="files"
				:audioRecords="audioRecords"
				@remove-file="removeFile"
				:key="audioFileUploading"
				:canModifyChannels="canModifyChannels"
			/>
			<div class="mb-32 mx-6" :class="{ '!mb-12': files.length }">
				<AppButton link v-if="canModifyChannels" size="xs" class="w-40 min-h-8" @click="uploadResourceFile">
					<AppIcon src="upload" class="color-primary" />
					Upload File(s)
				</AppButton>
			</div>
		</div>
		<AppFileUpload
			key="createResourceFile"
			ref="hiddenFileUploadImage"
			accepts="*"
			:multiple="true"
			@assetUp="fileUploaded"
			@filePicked="filePicked"
			@progress="progressUploaded"
			@errorUp="errorFileUploaded"
		/>
		<AppFileUpload
			key="uploadBannerImage"
			ref="hiddenUploadBannerImage"
			accepts="image/bmp,image/jpeg,image/png,img/svg+xml"
			@assetUp="bannerUploaded"
			@filePicked="bannerPicked"
			@progress="progressUploaded"
			@errorUp="errorFileUploaded"
		/>
		<div
			v-if="canModifyChannels"
			class="flex justify-end bg-white p-8 px-12 z-[10] bottom-0 w-full gap-4"
			:class="isEditMode || files.length ? 'sticky' : 'absolute'"
		>
			<AppButton size="sm" @click="saveResource(true)" outline :disabled="buttonsDisabled" :loading="draftLoading"> Save as Draft </AppButton>
			<AppButton size="sm" @click="saveResource(false)" :disabled="buttonsDisabled" :loading="publishLoading"> Publish </AppButton>
		</div>
	</div>
	<UnsplashImagePickerModal ref="unsplashImagePickerModal" @unsplashImagePicked="onUnsplashImagePicked" @close-modal="$emit('cancel-unsplash')" />
</template>

<script setup>
	import { ref, reactive, computed, onMounted, inject } from 'vue'
	import { useCommunityStore } from '@/stores/communities'
	import { useResourceStore } from '@/stores/resources'
	import StarterKit from '@tiptap/starter-kit'
	import Link from '@tiptap/extension-link'
	import Placeholder from '@tiptap/extension-placeholder'
	import { notify } from '@kyvg/vue3-notification'
	import { useRoute } from 'vue-router'
	import constants from '@/global_helper/constants'
	import { helpers, required } from '@vuelidate/validators'
	import { useVuelidate } from '@vuelidate/core'

	const stanAnalytics = inject('stanAnalytics')
	const props = defineProps({
		isEditMode: {
			type: Boolean,
			default: false,
		},
		propTitle: {
			type: String,
		},
		propSubTitle: {
			type: String,
		},
		propContent: {
			type: String,
		},
		propFiles: {
			type: Array,
		},
		propBannerImage: {
			type: String,
		},
	})
	const baseResource = {
		resource_id: undefined,
		status: constants.RESOURCE_STATUSES.INACTIVE,
		data: {
			banner_image: 'https://assets.stanwith.me/community/live/CommunityDefaultBanner/CommunityDefaultBanner.jpeg',
			title: '',
			subHeading: '',
			content: '',
			digital_assets: [],
		},
	}
	const emit = defineEmits(['saved'])

	const unsplashImagePickerModal = ref(null)
	const title = ref(props.propTitle ?? '')
	const subtitle = ref(props.propSubTitle ?? '')
	const content = ref(props.propContent ?? '')
	const modalTop = ref(null)
	const files = ref(props.propFiles ?? [])
	const audioRecords = ref([])
	const audioFileUploading = ref(false)
	const bannerImage = ref(props.propBannerImage ?? null)
	const hiddenFileUploadImage = ref(null)
	const hiddenUploadBannerImage = ref(null)
	const bannerLoading = ref(false)
	const publishLoading = ref(false)
	const draftLoading = ref(false)
	const communityStore = computed(() => useCommunityStore())
	const resourceStore = computed(() => useResourceStore())
	const isUploadingFiles = computed(() => files.value.filter(file => file?.ready !== true).length > 0)
	const route = useRoute()
	const canModifyChannels = computed(() => communityStore.value.canModifyChannels)
	const showButtons = ref(false)
	const resource = computed(() => resourceStore.value.resources.find(resource => resource.resource_id == route.params.resourceId) || baseResource)
	const validationRules = {
		title: { required: helpers.withMessage('Title is required', required) },
	}
	function openUnsplashImagePicker() {
		unsplashImagePickerModal.value.init()
	}
	function onUnsplashImagePicked(payload) {
		bannerImage.value = {
			url: payload.src,
			name: payload.name,
			id: payload.metadata.id,
		}

		// Set bannerLoading to false as the image has been picked
		bannerLoading.value = false
	}

	const v$ = useVuelidate(validationRules, {
		title,
		subtitle,
	})

	const currentCommunity = computed(() => communityStore.value.currentCommunity.slug)

	const fileUploaded = e => {
		const fileIndex = files.value.findIndex(f => f.id === e.metadata.id || f.metadata?.id === e.metadata.id)
		if (fileIndex !== -1) {
			if (!files.value[fileIndex].type.startsWith('audio')) {
				files.value[fileIndex] = {
					url: e.src,
					name: e.name,
					id: e.metadata.id,
					type: e.type,
					ready: true,
				}
			} else {
				files.value[fileIndex].options.uploaded = true
				files.value[fileIndex].src = e.src
				files.value[fileIndex].ready = true
				audioFileUploading.value = false
			}
		}
	}
	const bannerUploaded = e => {
		bannerImage.value = {
			url: e.src,
			name: e.name,
			id: e.metadata.id,
			type: e.type,
		}
		bannerLoading.value = false
	}
	const buttonsDisabled = computed(() => isUploadingFiles.value || draftLoading.value || publishLoading.value)
	const errorFileUploaded = e => {
		notify({ type: 'error', title: 'Uh Oh!', text: 'We ran into an issue uploading your file. Please try again.' })
		if (e.metadata?.id) {
			files.value.splice(
				files.value.indexOf(f => f.id === e.metadata?.id),
				1
			)
		}
		onAudioFileError(e)
	}
	const bannerPicked = file => {
		bannerImage.value = {
			name: file[0].name,
			type: file[0].type,
			id: file[0].metadata.id,
			progress: 0,
		}
		bannerLoading.value = true
	}
	const filePicked = filesArg => {
		for (const file in filesArg) {
			if (filesArg[file].type.startsWith('audio')) {
				files.value.push({
					...filesArg[file],
					type: filesArg[file].type,
					name: filesArg[file].name,
					options: { progress: 0, uploaded: false, error: null },
				})
				audioFileUploading.value = true
			} else {
				files.value.push({
					name: filesArg[file].name,
					type: filesArg[file].type,
					id: filesArg[file].metadata.id,
					progress: 0,
				})
			}
		}
	}
	const progressUploaded = file => {
		files.value.forEach(f => {
			if (f.id === file.metadata.id) {
				f.progress = file.progress
			} else if (f.metadata?.id === file.metadata?.id) {
				f.options.progress = file.progress
			}
		})
	}

	const onAudioFileError = data => {
		const idx = audioRecords.value.findIndex(f => f.metadata.id === data.id)
		if (idx >= 0) {
			audioRecords.value.splice(idx, 1)
		}
	}

	const uploadResourceFile = () => {
		hiddenFileUploadImage.value.onPickFile()
	}

	const uploadBannerImage = () => {
		hiddenUploadBannerImage.value.onPickFile()
	}

	const removeBannerImage = () => {
		bannerImage.value = ''
	}

	const removeFile = idx => {
		files.value.splice(idx, 1)
	}
	const toggleButtons = () => {
		showButtons.value = !showButtons.value
	}

	const saveResource = async isPublished => {
		if (isPublished) {
			draftLoading.value = true
		} else {
			publishLoading.value = true
		}
		const result = await v$.value.$validate()

		// Ignore error thrown by empty links
		const linkIsEmpty =
			v$.value.$errors?.length > 0 &&
			v$.value.$errors[0].$propertyPath === 'link.href' &&
			v$.value.$errors[1].$propertyPath === 'link.text' &&
			v$.value.$errors.length === 2
		if (!result && !linkIsEmpty) {
			draftLoading.value = false
			publishLoading.value = false
			modalTop.value.scrollIntoView({
				top: 0,
				behavior: 'smooth',
			})
			return
		}

		const body = {
			resource_id: props.isEditMode ? route.params.resourceId : undefined,
			status: isPublished ? constants.RESOURCE_STATUSES.INACTIVE : constants.RESOURCE_STATUSES.ACTIVE,
			data: {
				banner_image:
					(bannerImage.value?.url ?? bannerImage.value) ||
					'https://assets.stanwith.me/community/live/CommunityDefaultBanner/CommunityDefaultBanner.jpeg',
				title: title.value,
				subHeading: subtitle.value,
				content: content.value,
				digital_assets: files.value.map(file => {
					const { options, ...correctFile } = file
					const { metadata, ...restFile } = correctFile
					return {
						...restFile,
						id: restFile.id ?? metadata.id,
					}
				}),
			},
		}
		if (!props.isEditMode) {
			await resourceStore.value.createResource(body)
			stanAnalytics('community-resource-create')
		} else {
			await resourceStore.value.updateResource(body, route.params.resourceId)
			stanAnalytics('community-resource-update', {
				props: { resource_id: route.params.resourceId },
			})
		}

		emit('saved')

		draftLoading.value = false
		publishLoading.value = false
	}
</script>

<style lang="scss" scoped>
	:deep(.modal.modal-xl) {
		@apply rounded-none lg:rounded-xl h-screen lg:h-4/5;
	}
	.content-wrap {
		@apply h-full lg:flex lg:flex-row overflow-y-scroll lg:overflow-y-visible pb-20 lg:pb-0;
	}
	.resource-item-wrapper {
		@apply w-full lg:w-7/12 grow bg-white overflow-y-scroll overflow-x-hidden flex;
	}
	.app-icon {
		:deep(path) {
			fill: var(--stan-white);
		}
		&.no-fill {
			:deep(path) {
				stroke: var(--stan-white);
			}
		}
	}
</style>
